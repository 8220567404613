import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import RequestDelete from "./RequestDelete";
import PrivacyPolicy from "./PrivacyPolicy";
import apps from "./apps";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="contacts">
                  <h1>Ravaga Apps</h1>
                  <h3>Contact Us</h3>
                  <p>✉️ apps@ravaga.com</p>
                  <p>🌐 https://apps.ravaga.com</p>
                </div>
                <div className="app-list">
                  {apps.map((app) => (
                    <div key={app.id} className="app-list-item">
                      <h3>{app.name}</h3>
                      <a href={app.storeLink}>view in store</a>
                      <Link
                        to={`/privacy-policy/${app.id}`}
                        className="view-policy-button"
                      >
                        Privacy policy
                      </Link>
                    </div>
                  ))}
                </div>
                <RequestDelete />
              </>
            }
          />
          <Route path="/privacy-policy/:appName" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
