const RequestDelete = () => {
  return (
    <div className="remove-account">
      <h3>Remove account data</h3>
      <p>
        🚫 To remove your account data, please send an email to{" "}
        <a href="mailto:apps@ravaga.com">apps@ravaga.com</a>
      </p>
    </div>
  );
};

export default RequestDelete;
