import React from "react";
import { useParams } from "react-router-dom";
import apps from "./apps";

function PrivacyPolicy() {
  const { appName } = useParams();
  const appData = apps.find((app) => app.id === appName);

  return (
    <div className="privacy-policy">
      <h2>Privacy Policy for {appData.name}</h2>
      <div>
        {appData.policy.map((string) => (
          <p>{string}</p>
        ))}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
