const apps = [
  {
    id: "say-it-right",
    name: "Say It Right",
    storeLink: "https://play.google.com/store/apps/details?id=com.say.it.right",
    policy: [
      "This Privacy Policy describes how Ravaga DOO ('we,' 'us,' or 'our') collects, uses, and shares information in connection with your use of our services through our mobile application ('App').",
      "By using our App, you agree to the collection and use of information in accordance with this policy.",
      "Information Collection and Use",
      "When you use our App, we collect the following types of information:",
      "Audio Data: We collect and analyze the audio data of what you pronounce in order to provide feedback and improvements on your pronunciation.",
      "App Usage Data: We collect information such as your overall pronunciation score, pronunciation score for each phoneme, your usage streak, and last login information.",
      "Personal Identification Information",
      "The App uses anonymous authentication where each user is assigned a random unique identifier by Firebase. We do not have any mechanisms for logging in with a username/password or through Google/Apple/Facebook.",
      "Information Sharing and Disclosure",
      "We do not share your information with third parties, except as described below:",
      "Service Providers: We may employ trusted third-party companies and individuals to facilitate our service, to provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. We only provide these third parties with access to your information so that they can perform these tasks on our behalf.",
      "Compliance with Laws: We may disclose your information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our App.",
      "Chat Data: Please note that the chat data goes directly to the OpenAI ChatGPT API and is not stored by us. For information on how OpenAI uses this data, please review their privacy policy.",
      "Security",
      "The security of your data is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.",
      "Changes to This Privacy Policy",
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
      "Contact Us",
      "If you have any questions about this Privacy Policy, please contact us:",
      "By email: apps@ravaga.com",
      "By visiting this page on our website: https://apps.ravaga.com",
      "Your continued use of our App following the posting of changes to this policy will be deemed your acceptance of those changes.",
    ],
  },
  {
    id: "speak-app-chat",
    name: "Speak App Chat",
    storeLink:
      "https://play.google.com/store/apps/details?id=com.speak.app.chat",
    policy: [
      "This Privacy Policy describes how Ravaga DOO ('we,' 'us,' or 'our') collects, uses, and shares information in connection with your use of our services through our mobile application ('App').",
      "By using our App, you agree to the collection and use of information in accordance with this policy.",
      "Information Collection and Use",
      "When you use our App, we collect the following types of information:",
      "Audio Data: We collect and analyze the audio data of what you pronounce in order to provide feedback and improvements on your pronunciation.",
      "App Usage Data: We collect information such as your overall pronunciation score, pronunciation score for each phoneme, your usage streak, and last login information.",
      "Personal Identification Information",
      "The App uses anonymous authentication where each user is assigned a random unique identifier by Firebase. We do not have any mechanisms for logging in with a username/password or through Google/Apple/Facebook.",
      "Information Sharing and Disclosure",
      "We do not share your information with third parties, except as described below:",
      "Service Providers: We may employ trusted third-party companies and individuals to facilitate our service, to provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. We only provide these third parties with access to your information so that they can perform these tasks on our behalf.",
      "Compliance with Laws: We may disclose your information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our App.",
      "Chat Data: Please note that the chat data goes directly to the OpenAI ChatGPT API and is not stored by us. For information on how OpenAI uses this data, please review their privacy policy.",
      "Security",
      "The security of your data is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.",
      "Changes to This Privacy Policy",
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
      "Contact Us",
      "If you have any questions about this Privacy Policy, please contact us:",
      "By email: apps@ravaga.com",
      "By visiting this page on our website: https://apps.ravaga.com",
      "Your continued use of our App following the posting of changes to this policy will be deemed your acceptance of those changes.",
    ],
  },
  // add more apps here
];

export default apps;
